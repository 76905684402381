@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: rgb(0, 63, 145);
  --secondary: rgb(93, 169, 233);
}

.hero {
  background-image: url("https://res.cloudinary.com/shishirasdjfadsfj/image/upload/v1657875012/CollegeGrahasthiWeb/home-bg_hgu3rq.png");
  background-size: cover;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 6rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.hero-logo {
  width: 10rem;
}

.logo {
  width: 5rem;
}

.login {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.login a {
  text-decoration: none;
  padding: 0 0.5rem;
  color: rgb(209 213 219);
}

.login a:hover {
  color: rgb(255, 255, 255);
}

.hero-des {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5rem 0 7rem 0;
}

.hero-des .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.title .h1 {
  font-size: 4.5rem;
  line-height: 1;
}

.title .p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  background-color: rgb(255, 255, 255);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: black;
  border-radius: 0.75rem;
}

.search-bar input {
  width: 100%;
  padding: 0.5rem;
  border: 0;
  border-radius: 0.75rem;
}

.search-bar input:focus {
  outline: none;
}

.search-bar img {
  width: 2.5rem;
  padding: 0.5rem;
}

.search-bar img:hover {
  cursor: pointer;
}

.hero-points {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.75rem;
}

.hero-points .points {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}

.hero-points .points img {
  width: 1.25rem;
}

.hero-points .points div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0 0 0 0.25rem;
}

.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--primary);
  background-image: url("https://res.cloudinary.com/dl2mkfkqh/image/upload/v1657886865/fianl/banner_urskaa.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  margin: 2.5rem 1.5rem;
  border-radius: 0.75rem;
}

.banner .p {
  font-size: 3rem;
  line-height: 1;
  color: rgb(255, 255, 255);
}

.button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border-radius: 0.75rem;
}

.services .h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  padding: 2.5rem;
  padding-bottom: 0;
  color: var(--primary);
}

.section-line {
  background-color: var(--primary);
  width: 5rem;
  height: 0.25rem;
  margin-left: 2.5rem;
}

.services-card {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
}

.services-image {
  position: relative;
  width: 24rem;
  height: 18rem;
  border-radius: 0.75rem;
}

.services-image .image-div {
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.image-div img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  z-index: 0;
}

.image-div a {
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.services-image .name-div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 63, 145, 0.5);
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: white;
  font-weight: 700;
  border-radius: 0.75rem;
  opacity: 0;
  z-index: 1;
}

.services-image .name-div:hover {
  opacity: 1;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--primary);
  color: white;
  padding: 5rem;
}

.footer .company-des {
  display: flex;
  flex-direction: column;
}

.company-des .h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.copyright-title {
  color: rgb(229 231 235);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.copyright-info {
  display: flex;
  width: 16rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: justify;
  color: rgb(229 231 235);
}

.stars {
  display: flex;
  gap: 0.5rem;
  padding-top: 2.5rem;
}

.stars img {
  width: 2.5rem;
}

.footer-des {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.footer-des-card {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.footer-des-card .h1 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.footer-des-card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-des a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: var(--primary);
}

.footer-des a:hover {
  border-color: rgb(255, 255, 255);
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact .h1 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.direct-contact-div,
.social-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.direct-contact {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 0.25rem;
  border-style: solid;
  border-color: black;
  border-width: 0;
  border-bottom-width: 0.5rem;
  border-radius: 0.5rem;
}

.direct-contact:hover {
  border-color: white;
}

.direct-contact div {
  color: rgb(0, 0, 0);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

a img {
  width: 1.5rem;
  padding-right: 0.5rem;
}

.social-div {
  display: flex;
  flex-direction: column;
}

.social {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-style: solid;
  border-color: black;
  border-width: 0;
  border-bottom-width: 0.5rem;
}

.social:hover {
  border-color: white;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 6rem;
  line-height: 1;
  color: var(--primary);
  font-weight: 700;
  background-color: rgba(93, 169, 233, 0.3);
}


.navbar {
  background-color: var(--primary) !important;
}

.navbar-expand-lg {
  padding: 0px 16px;
}

.nav-link {
  color: white !important;
}

.navbar-brand {
  color: white !important;
  font-size: 2.5rem;
}

.navbar-nav {
  margin-left: auto !important;
}

.nav-item {
  font-size: 1rem;
}

.smallimg {
  height: 200px;
  width: 100% !important;
  border-radius: 5px !important;
}

h1 {
  font-size: 20px !important;
}

.btn {
  background-color: rgb(0, 63, 145) !important;
  color: white !important;
  box-shadow: none !important;
}

.bs {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px !important;
  border-radius: 5px !important;
  padding: 20px !important;
}

.bigimg {
  height: 400px;
  border-radius: 5px !important;
  /* width: 100% !important; */
  /* border-radius: 5px !important; */
}

input,
select,
.ant-picker {
  box-shadow: none !important;
  margin-top: 10px;
  width: 100% !important;
  height: 35px !important;
}

h2 {
  font-size: 30px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.ant-picker-input input {
  border: none !important;
}

p {
  font-size: 15px !important;
}

.landing {
  background-color: black !important;
  height: 100vh;
  padding-top: 130px;
}

.landingBtn {
  background-color: white !important;
  color: black !important;
  margin-top: 20px !important;
}

.ant-calendar-picker {
  width: auto !important;
}

.navbar-toggler {
  border: 0px;
}

.navbar-toggler:focus {
  outline: none;
}


@media screen and (max-width: 640px) {
  .nav {
    padding: 0 3rem;
  }

  .login {
    display: none;
  }

  .hero-des .title .h1 {
    font-size: 2rem;
  }

  .hero-des .title .p {
    font-size: 1rem;
  }

  .banner {
    flex-direction: column;
  }

  .banner .p {
    font-size: 2rem;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .company-des {
    justify-content: center;
    align-items: center;
  }

  .stars {
    display: none;
  }

  .footer-des-card {
    display: none;
  }

  .footer-des {
    justify-content: center;
  }

  .contact {
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }

  .social-div {
    flex-direction: row;
  }

  .coming-soon {
    font-size: 3rem;
  }

  .ant-picker-panels { 
    flex-direction: column;
  } 
}

@media screen and (max-width: 375px) {
  .nav {
    padding: 0 1rem;
  }

  .hero-points {
    flex-direction: column;
  }

  .hero-des .title .h1 {
    font-size: 1.5rem;
  }

  .hero-des .title .p {
    font-size: 0.75rem;
  }

  .banner .p {
    font-size: 1.5rem;
  }

  .company-des .h1 {
    font-size: 1.25rem;
  }


  .coming-soon {
    font-size: 2rem;
  }

}